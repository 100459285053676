import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import Avatar from "./Avatar";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        background:
            "linear-gradient(rgb(77, 121, 154) 0%, rgba(77, 121, 154, 0) 100%)",
    },
    mainGrid: {
        maxWidth: "90%",
        margin: "auto",
    },
    column1: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    column2: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: " center",
    },
    me: {
        marginTop: 0,
        marginBottom: 0,
        color: "white",
    },
    position: {
        marginTop: 0,
        marginBottom: 0,
        color: "white",
    },
    fabButton: {
        color: "white",
        marginLeft: theme.spacing(2),
        backgroundColor: "rgb(218 166 125)",
        "&:hover": {
            backgroundColor: "rgb(196 139 110)",
        },
    },
    workExperience: {
        paddingTop: theme.spacing(3),
        color: "white",
    },
}));

function Header() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.mainGrid}>
                <Grid item xs={12} md={9} className={classes.column1}>
                    <Avatar />
                    <div style={{ paddingLeft: 20 }}>
                        <h1 className={classes.me}>Allan Trabuco</h1>
                        <h4 className={classes.position}>
                            Front-End Web Developer
                        </h4>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} className={classes.column2}>
                    <Grid item>
                        <Fab
                            size="small"
                            className={classes.fabButton}
                            aria-label="LinkedIn"
                            href="https://www.linkedin.com/in/atrabuco"
                            target="_blank"
                        >
                            <LinkedInIcon />
                        </Fab>
                    </Grid>
                    <Grid item>
                        <Fab
                            size="small"
                            className={classes.fabButton}
                            href="https://github.com/allantrabuco"
                            target="_blank"
                        >
                            <GitHubIcon />
                        </Fab>
                    </Grid>
                    {/* <Fab size="medium" color="primary" aria-label="GitHub">
                    </Fab> */}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Typography
                        component="h4"
                        variant="h4"
                        align="center"
                        className={classes.workExperience}
                    >
                        Work experience, Education and Training
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default Header;
