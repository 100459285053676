const timelineItemList: any[] = [
    {
        type: "w",
        position: "Senior Developer",
        company: "Capital International Group",
        location: "Isle of Man",
        startDate: "2020/02/01",
        endDate: "",
        employmentDuration: "6 months",
        responsibilities: [
            {
                description:
                    "# Analysis, development and helpdesk of:\n--- Portal V3 and V4 (telecommunications services)",
                technologies:
                    "Angular, Cypress, Bootstrap 4, PHP (Laravel), Python, HTML 5, CSS 3, JavaScript, Ajax, MySQL and Oracle.",
            },
        ],
    },
    {
        type: "w",
        position: "Senior Web Developer",
        company: "plan.com",
        location: "Isle of Man",
        startDate: "2017/02/01",
        endDate: "2020/01/31",
        employmentDuration: "3 yrs",
        responsibilities: [
            {
                description:
                    "# Analysis, development and helpdesk of: --- Portal V3 and V4 (telecommunications services)",
                technologies:
                    "PHP (CodeIgniter), jQuery, Bootstrap 3, React (with template), HTML 5, CSS 3, JavaScript, Ajax, MySQL and Oracle.",
            },
        ],
    },
    {
        type: "w",
        position: "Senior IT Analyst and Full Stack Developer",
        company: "Allan Trabuco - Tecnologias de Informação, Unipessoal Lda",
        location: "Lisbon Area, Portugal",
        startDate: "2016/03/01",
        endDate: "2017/01/31",
        employmentDuration: "11 mos",
        responsibilities: [
            {
                description:
                    "# Analysis and development of:\n--- Web application for sale of treated satellite images according to spectral bands , clouds and other.\n--- Corporate intranets (SaaS distribution).\n--- Management software for clinics and health professionals (SaaS distribution).\n--- Banking fee structure maintenance.",
                technologies:
                    "AngularJS 1.x, jQuery, Bootstrap, HTML 5, CSS 3, Javascript, Ajax, PHP, Laravel, Google Maps API, Blaze Advisor, JSON, GeoJSON, XML, IBM mainframe, MySQL/MariaDB and DB2.",
            },
        ],
    },
    {
        type: "w",
        position: "Senior IT Analyst and Scrum Master",
        company: "INSIX Soluções Inteligentes Ltda",
        location: "Salvador, Bahia, Brazil",
        startDate: "2014/05/01",
        endDate: "2016/03/31",
        employmentDuration: "1 yr 11 mos",
        responsibilities: [
            {
                description:
                    '# Analysis and development of:\n--- "Aqui tem Remédio" (mobile app and site) for Municipal Health Secretariat of São Paulo. (http://aquitemremedio.prefeitura.sp.gov.br/)\n--- "Purchasing and Procurement Management System" for Municipal Health Secretariat of São Paulo.\n--- “Management Dashboard" (BI) for Municipal Health Secretariat of São Paulo.\n--- Claim Management System (Enterprise Performance Management (EPM)) for TPC Group (http://www.grupotpc.com)',
                technologies:
                    "Sencha ExtJS 5.x.x, AngularJS 1.x, jQuery, Bootstrap, Ionic, Google Maps API, HTML 5, CSS 3, Javascript, Ajax, JSON, XML, Microsoft Visual Studio 2013 / 2015 (C#), Microsoft SQL Server, Oracle Database 11g and Microsoft Azure.",
            },
        ],
    },
    {
        type: "w",
        position: "Full Stack Developer and Manager",
        company: "Kylver Technologies",
        location: "Brazil",
        startDate: "2013/05/01",
        endDate: "2014/08/31",
        employmentDuration: "1 yr 4 mos",
        responsibilities: [
            {
                description:
                    "# Software development.\n# Consulting in Information Technology.\n# Technical support, maintenance and other services in Information Technology.\n# Management software for clinics and health professionals (SaaS distribution).",
                technologies:
                    "Sencha ExtJS 4.2.x, ExtJS 5.0.x and ExtJS 5.1.x; HTML, CSS 3, Javascript, Ajax, JSON, PHP, CodeIgniter Web Framework and MySQL.",
            },
        ],
    },
    {
        type: "w",
        position: "IT Teacher of Web Development Course",
        company: "SENAI Cetind",
        location: "Salvador, Bahia, Brazil",
        startDate: "2014/03/01",
        endDate: "2014/03/31",
        employmentDuration: "1 mo",
        responsibilities: [
            {
                description:
                    "# Classes about HTML, Javascript, CSS, PHP and MySQL.",
                technologies: "",
            },
        ],
    },
    {
        type: "w",
        position: "Book Reviewer",
        company: "Editora Alta Books",
        location: "Rio de Janeiro, Brazil",
        startDate: "2013/05/01",
        endDate: "2013/05/31",
        employmentDuration: "1 mo",
        responsibilities: [
            {
                description:
                    '# Responsible for the technical review of the translation into Portuguese-Brazil.\nOriginal book title (US): "Linux Bible, 8th Edition" - ISBN: 978-11-1821-854-9.\nBook title in Brazil: "Linux A Bíblia - O Mais Abrangente e Definitivo Guia Sobre Linux" ISBN: 978-85-7608-799-1 (Fedora / Red Hat Enterprise / CentOS).',
                technologies: "",
            },
        ],
    },
    {
        type: "w",
        position: "Full Stack Developer (Freelance)",
        company: "AFFINITY Portugal",
        location: "Lisbon Area, Portugal",
        startDate: "2012/06/01",
        endDate: "2012/12/31",
        employmentDuration: "7 mos",
        responsibilities: [
            {
                description:
                    "# Responsible for analysis and development of all stages of assembling a unique ERP.",
                technologies:
                    "Sencha ExtJS 4.1.x, HTML, CSS 3, Javascript, Ajax, JSON, XML, PHP, CodeIgniter Web Framework, MySQL and Apache HTTP Server.",
            },
        ],
    },
    {
        type: "w",
        position: "IT Analyst, Software Developer and IT Manager (Freelance)",
        company: "Several companies in Portugal",
        location: "Portugal",
        startDate: "2002/09/01",
        endDate: "2012/12/31",
        employmentDuration: "10 yrs 4 mos",
        responsibilities: [
            {
                description:
                    "# Analysis, development and software management to BZI. The BZI is a tool created to conduct internal audit into IBM systems (OS/390, z/OS).",
                technologies:
                    "HTML, jQuery, Javascript, ExtJS 2.x and ExtJS 3.x, JSON, Ajax, PHP 4 and PHP 5, MySQL, Eclipse (IDE), Apache HTTP Server, Microsoft Windows and Linux (CentOS, Fedora, OpenSUSE, RedHat, SUSE and Caixa Mágica).",
            },
        ],
    },
    {
        type: "w",
        position: "IT Developer Specialist in Sencha ExtJS",
        company: "Celfocus / KCSiT",
        location: "Lisbon Area, Portugal",
        startDate: "2012/04/01",
        endDate: "2012/09/30",
        employmentDuration: "6 mos",
        responsibilities: [
            {
                description:
                    "# Responsible for analysing and developing customized components in Sencha ExtJS, and for defining ExtJS 'best practices'.\n# Project: Unified Front End (UFE) to UNITEL S.A.",
                technologies:
                    "Sencha ExtJS 4.0.7 and ExtJS 4.1, SmartSVN, Oracle webLogic Server 12c (12.1.1), SpringSource Tool Suit.",
            },
        ],
    },
    {
        type: "w",
        position: "Senior IT Analyst and Software Developer",
        company: "Montepio",
        location: "Lisbon",
        startDate: "2008/04/01",
        endDate: "2012/03/31",
        employmentDuration: "4 yrs",
        responsibilities: [
            {
                description:
                    '# Responsible for Analysing, developing and implementing "Pricing Tools" - Management tool and publication of the price list of the bank.',
                technologies:
                    "ExtJS 3.x, Javascript, JSON, Ajax, PHP 5, CodeIgniter, MySQL and Apache HTTP Server.",
            },
            {
                description:
                    "# Responsible for analysing and managing the pricing of bank ",
                technologies: "FICO Blaze Advisor 6.x and Java.",
            },
            {
                description:
                    "# Analysis and 'Customers' application development",
                technologies:
                    "Cobol2 (z/OS), CICS, MVS, DB2 8 for z/OS, TSO, JCL, IBM Products (File-Manager and File Manager, DB2).",
            },
        ],
    },
    {
        type: "w",
        position: "Senior IT Analyst, IT Developer and IT Manager (Freelance)",
        company: "Probatronica Sistemas Informáticos",
        location: "Lisbon Area, Portugal",
        startDate: "2008/04/01",
        endDate: "2009/08/31",
        employmentDuration: "1 yr 5 mos",
        responsibilities: [
            {
                description:
                    "# Responsible for analysing, developing and also for the Helpdesk management software (OSHD - Open Source Helpdesk).",
                technologies:
                    "ExtJS 2.x and ExtJS 3.x, Javascript, JSON, Ajax, PHP 5, MySQL, Microsoft SQL Server, Eclipse (IDE), Apache HTTP Server, Microsoft Windows and Linux (CentOS, Fedora, OpenSUSE, RedHat, SUSE and Caixa Mágica).",
            },
        ],
    },
    {
        type: "w",
        position: "Senior IT Analyst and Software Developer",
        company:
            "Companhia de Seguros Fidelidade-Mundial, S.A. / Império Bonança - Companhia de Seguros, S.A.",
        location: "Lisbon, Portugal",
        startDate: "1999/02/01",
        endDate: "2008/03/31",
        employmentDuration: "9 yrs 2 mos",
        responsibilities: [
            {
                description:
                    "# Analysis and development of Enterprise Performance Management (EPM).",
                technologies:
                    "HTML, Javascript, PHP 3, 4 and 5, MySQL 4.x and 5.x, Apache HTTP Server and Microsoft Windows XP.",
            },
            {
                description:
                    "# Analysis and development of financial software.",
                technologies:
                    "Cobol2 (OS/390), CICS for OS/390, MVS, DB2 for OS/390, TSO, CA-Endevor®, Viasoft Products (SmartTest, SmartTest-IMS and SmartEdit), JCL for OS/390, ChangeMan, CompuWare Products (File-Aid, Cics Abend Aid/FX and XPEDITER for TSO), BMC Software Administrative Products for DB2.",
            },
        ],
    },
    {
        type: "w",
        position: "Senior IT Analyst and Software Developer",
        company: "Datacomp II TI",
        location: "Lisbon, Portugal",
        startDate: "1999/02/01",
        endDate: "1999/08/31",
        employmentDuration: "7 mos",
        responsibilities: [
            {
                description:
                    "# Analysis and development of several systems to the insurance company Lusitania - Companhia de Seguros SA.",
                technologies: "Pro-Cobol and Oracle database.",
            },
            {
                description:
                    "# Management, analysis and software development for solid waste management for the Municipality of Seixal.",
                technologies:
                    "Microsoft Visual Basic 5.0 and 6.0, Informix, Oracle database and Microsoft Access",
            },
            {
                description:
                    "# Trainer in Microfocus COBOL, CICS, DB2, Microsoft Visual Basic 5 and Java/JBuilder.",
                technologies: "",
            },
            {
                description:
                    "# Software developer to Portuguese social security systems.",
                technologies: "Delphi 5 and Microsoft SQL Server.",
            },
        ],
    },
    {
        type: "w",
        position: "Full and Senior Programmer",
        company: "CPM - Sistemas Ltda",
        location: "Florianópolis - Santa Catarina - Brazil",
        startDate: "1998/05/01",
        endDate: "1999/01/31",
        employmentDuration: "9 mos",
        responsibilities: [
            {
                description:
                    "# Development and maintenance of banking software (Bradesco and Itaú).",
                technologies: "Microfocus COBOL, WorkBench, DB2 and IMS - DC.",
            },
        ],
    },
    {
        type: "w",
        position: "High School Teacher (IT)",
        company: "Academia de Comércio de Santa Catarina",
        location: "Florianópolis - Santa Catarina - Brazil",
        startDate: "1997/06/01",
        endDate: "1998/10/30",
        employmentDuration: "1 yr 5 mos",
        responsibilities: [
            {
                description:
                    "# Teaching programming languages such as Microfocus Cobol, Clipper 5.x, and Microsoft Visual Basic 4.0 and 5.0.",
            },
        ],
    },
    {
        type: "w",
        position: "Full developer",
        company: "Datasul",
        location: "Florianópolis - Santa Catarina - Brazil",
        startDate: "1997/04/01",
        endDate: "1997/08/31",
        employmentDuration: "5 mos",
        responsibilities: [
            {
                description:
                    "# Software development and maintenance.\n# Projects:\n--- EDI (Electronic Data Interchange)\n--- Shareholding control software (Algar Group) (http://www.algar.com.br/)\n",
                technologies: "Progress",
            },
        ],
    },
    {
        type: "w",
        position: "IT Teacher and Head of Creating Courses",
        company: "Grupo - CECAI - Net School Informática",
        location: "Florianópolis - Santa Catarina - Brazil",
        startDate: "1995/04/01",
        endDate: "1996/12/31",
        employmentDuration: "1 yr 9 mos",
        responsibilities: [
            {
                description:
                    "# Teaching classes of programming languages such as Microsoft Visual Basic and Microfocus COBOL, Microsoft Access, Microsoft SQL Server and Microsoft Office.\n# Responsible for creating the Microsoft Visual Basic course.",
                technologies: "",
            },
        ],
    },
    {
        type: "w",
        position: "High School Teacher (IT)",
        company:
            "Prevê - Sociedade Civil de Ensino (http://grupopreve.com.br/)",
        location: "Bauru - São Paulo - Brazil",
        startDate: "1994/08/01",
        endDate: "1995/03/31",
        employmentDuration: "8 mos",
        responsibilities: [
            {
                description:
                    "# Teaching classes of programming languages such as Microfocus COBOL, Dataflex and Clipper 5.x.",
                technologies: "",
            },
        ],
    },
    {
        type: "w",
        position: "Trainee And Junior Programmer",
        company: "Souza Reis Indústria e Comércio Ltda.",
        location: "Bauru - São Paulo - Brazil",
        startDate: "1993/12/01",
        endDate: "1995/01/31",
        employmentDuration: "1 yr 2 mos",
        responsibilities: [
            {
                description:
                    "# Develop and maintain in all company systems.\n# Administrator HP-UX and DIGIREDE networks.\n# Give maintenance on equipment type printers, modems and terminals.",
                technologies: "Microfocus Cobol, Dataflex and Unix.",
            },
        ],
    },
    {
        type: "w",
        position: "IT Teacher, Head of Creating Courses and IT Developer",
        company: "Data Master - Treinamento e Sistemas",
        location: "Bauru - São Paulo - Brazil",
        startDate: "1993/12/01",
        endDate: "1991/08/31",
        employmentDuration: "2 yrs 5 mos",
        responsibilities: [
            {
                description:
                    "# Teaching classes of MS-DOS, Microsoft Windows, dBase III Plus, Summer'87 Clipper, Clipper 5.x, Microfocus Cobol, GW-Basic, Lotus 123, WordStar.\n# Responsible for creating and updating the teaching materials of various courses.\n# Acting as analyst and programmer in several projects using Clipper 5.x",
                technologies: "",
            },
        ],
    },
    {
        type: "s",
        course: "Analyst and Development of Systems",
        institution: "Estácio (http://portal.estacio.br/)",
        location: "Brazil",
        startDate: "2015/01/01",
        endDate: "2016/01/01",
        duration: 0,
        description: "Degree in analysis and development of systems.",
        finished: "FALSE",
    },
    {
        type: "s",
        course: "Game Design",
        institution:
            "California Institute of the Arts in Coursera (https://www.coursera.org/)",
        location: "online",
        startDate: "2015/09/14",
        endDate: "2015/10/13",
        duration: 0,
        description:
            "Introduction to Game Design (CaLARTS) - Final Grade: 93.8%",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Game Development (Unity 3D)",
        institution:
            "Michigan State University in Coursera (https://www.coursera.org/)",
        location: "online",
        startDate: "2015/09/14",
        endDate: "2015/10/13",
        duration: 0,
        description: "Introduction to Game Development - Final Grade: 99.5%",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Python Programmer",
        institution:
            "University of Michigan in Coursera (https://www.coursera.org/)",
        location: "online",
        startDate: "2015/09/14",
        endDate: "2015/10/05",
        duration: 0,
        description: "Getting Started with Python - Final Grade: 99.1%",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Java Programmer",
        institution:
            "CITEFORMA - Centro de Formação Profissional dos Trabalhadores de Escritório, Comércio, Serviços e Novas Tecnologias (http://ww.citeforma.pt/)",
        location: "Portugal",
        startDate: "2010/03/20",
        endDate: "2010/05/15",
        duration: 50,
        description: "Distributed systems programming with Java for the Web.",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Java Programmer",
        institution:
            "CITEFORMA - Centro de Formação Profissional dos Trabalhadores de Escritório, Comércio, Serviços e Novas Tecnologias (http://ww.citeforma.pt/)",
        location: "Portugal",
        startDate: "2010/01/16",
        endDate: "2010/03/06",
        duration: 50,
        description: "Distributed systems programming with Java.",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Linux Administrator",
        institution:
            "CITEFORMA - Centro de Formação Profissional dos Trabalhadores de Escritório, Comércio, Serviços e Novas Tecnologias (http://ww.citeforma.pt/)",
        location: "Portugal",
        startDate: "2009/03/21",
        endDate: "2009/05/23",
        duration: 50,
        description: "Service configuration on a Linux server (CentOS).",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Linux Administrator",
        institution:
            "CITEFORMA - Centro de Formação Profissional dos Trabalhadores de Escritório, Comércio, Serviços e Novas Tecnologias (http://ww.citeforma.pt/)",
        location: "Portugal",
        startDate: "2009/02/07",
        endDate: "2009/02/28",
        duration: 50,
        description: "Server Open Source Operating System (CentOS).",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "C# Programmer",
        institution:
            "CITEFORMA - Centro de Formação Profissional dos Trabalhadores de Escritório, Comércio, Serviços e Novas Tecnologias (http://ww.citeforma.pt/)",
        location: "Portugal",
        startDate: "2006/10/21",
        endDate: "2006/11/25",
        duration: 42,
        description: "Programming in Visual C# .NET 2005",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "C++ Programmer",
        institution:
            "CITEFORMA - Centro de Formação Profissional dos Trabalhadores de Escritório, Comércio, Serviços e Novas Tecnologias (http://ww.citeforma.pt/)",
        location: "Portugal",
        startDate: "2006/09/02",
        endDate: "2006/10/14",
        duration: 42,
        description: "Programming in Visual C++ .NET 2005",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Red Hat Linux Administrator",
        institution:
            "Sybase Portugal - RedHat Certified Training Partner (http://www.syone.com/) ",
        location: "Portugal",
        startDate: "2006/09/04",
        endDate: "2006/09/14",
        duration: 32,
        description:
            "RH253 - Red Hat Linux Networking & Security Administration",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Red Hat Linux Administrator",
        institution:
            "Sybase Portugal - RedHat Certified Training Partner (http://www.syone.com/)",
        location: "Portugal",
        startDate: "2006/07/17",
        endDate: "2006/07/27",
        duration: 32,
        description: "RH131 - Red Hat Linux System Administration",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Red Hat Linux Administrator",
        institution:
            "Sybase Portugal - RedHat Certified Training Partner (http://www.syone.com/)",
        location: "Portugal",
        startDate: "2006/06/26",
        endDate: "2006/07/06",
        duration: 32,
        description: "RH033 - Red Hat Linux Essentials",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Physicist",
        institution:
            "UFSC - Federal University of Santa Catarina (http://ufsc.br/)",
        location: "Florianópolis - Santa Catarina - Brazil",
        startDate: "1997/01/01",
        endDate: "1998/01/01",
        duration: 0,
        description: "Degree in Physics",
        finished: "FALSE",
    },
    {
        type: "s",
        course: "Physicist",
        institution:
            "UNESP -  Paulista State University (http://www.unesp.br/)",
        location: "Bauru - São Paulo - Brazil",
        startDate: "1995/01/01",
        endDate: "1996/01/01",
        duration: 0,
        description: "Degree in Physics",
        finished: "FALSE",
    },
    {
        type: "s",
        course: "Unix Administrator",
        institution: "Centro Educacional Kenner",
        location: "São Paulo - Brazil",
        startDate: "1994/01/01",
        endDate: "1994/01/01",
        duration: 40,
        description: "HP-UX foundation",
        finished: "TRUE",
    },
    {
        type: "s",
        course: "Technical Data Processing",
        institution:
            "Prevê Objetivo - Sociedade Civil de Ensino (http://grupopreve.com.br/)",
        location: "Bauru - São Paulo - Brazil",
        startDate: "1991/01/01",
        endDate: "1993/12/31",
        duration: 0,
        description:
            "(Technical High School) Studies in functional analysis, database and different languages such as Basic, GW-Basic, Assembler, Cobol, C, C ++, Pascal, dBase III Plus and Clipper.",
        finished: "TRUE",
    },
];

export default timelineItemList;
