import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Timeline from "./Timeline";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // height: "calc(100vh - 112px)",
        // height: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        // fontSize: "calc(10px + 2vmin)",
        // color: "white",
        // overflow: "auto",
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
}));

function Body() {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.root}
        >
            <Timeline />
        </Grid>
    );
}

export default Body;
