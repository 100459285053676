import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
// import Typography from "@material-ui/core/Typography";
import Header from "./Header";
import Body from "./Body";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        background:
            "linear-gradient(rgba(77, 121, 154, 0.6) 0%, rgba(135, 160, 179, 0.6) 30%, rgba(197, 117, 45, 0.6) 70%, rgba(183, 73, 15, 0.6) 90%, rgba(47, 17, 7, 0.6) 100%)",
    },
    // workExperience: {
    //     color: "white",
    //     width: "100%",
    //     backgroundColor: "rgb(170 189 204 / 92%)",
    //     position: "fixed",
    //     top: 190,
    //     zIndex: 1000,
    // },
}));

function App() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header />
            {/* <Typography
                component="h4"
                variant="h4"
                align="center"
                className={classes.workExperience}
            >
                Work experience, Education and Training
            </Typography> */}
            <Body />
        </div>
    );
}

export default App;
