import React from "react";
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { format, isValid, intervalToDuration, addDays } from "date-fns";
import { v4 as uuidv4 } from "uuid";

// import BusinessCenterRoundedIcon from "@material-ui/icons/BusinessCenterRounded";
import BeachAccessRoundedIcon from "@material-ui/icons/BeachAccessRounded";
import WorkRoundedIcon from "@material-ui/icons/WorkRounded";
import SchoolRoundedIcon from "@material-ui/icons/SchoolRounded";

import timeline from "./data/timeline";

const completeDate = (props: any) => {
    const { date, isBegin = false, isEnd = false } = props;

    let lDate;

    try {
        lDate = new Date(date);
        console.log(lDate);
    } catch (error) {
        console.error("no!");
    }
};

const TimeLineItem = (props: any) => {
    const { entry } = props;

    let startDate, endDate, monthsYears, years, months, dateString;

    if (entry.endDate !== "") {
        if (!isValid(new Date(entry.endDate))) {
        }

        completeDate({ date: entry.startDate, isBegin: true });
        completeDate({ date: entry.endDate, isEnd: true });

        startDate = new Date(entry.startDate);
        endDate = new Date(entry.endDate);

        monthsYears = intervalToDuration({
            start: startDate,
            end: addDays(endDate, 1),
        });

        years = monthsYears.years ? monthsYears.years : 0;
        months = monthsYears.months ? monthsYears.months : 0;

        const yearString = years > 1 ? "Years" : "Year";
        const monthString = months > 1 ? "Months" : "Month";

        dateString =
            format(new Date(startDate), "MMMM/yyyy") +
            " - " +
            format(new Date(endDate), "MMMM/yyyy");

        if (years > 0) {
            dateString = `${dateString} (${years.toString()} ${yearString}`;
        }

        if (months > 0) {
            if (years > 0) {
                dateString = `${dateString} and ${months} ${monthString})`;
            } else {
                dateString = `${dateString} (${months} ${monthString})`;
            }
        } else {
            if (years > 0) {
                dateString = `${dateString})`;
            }
        }
    } else {
        dateString =
            format(new Date(entry.startDate), "MMMM/yyyy") + " - present ";
    }

    if (entry.type === "s") {
        return (
            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={dateString}
                iconStyle={{
                    background: "rgb(238, 101, 33)",
                    color: "#fff",
                }}
                icon={<SchoolRoundedIcon />}
            >
                <h4 className="vertical-timeline-element-title">
                    {entry.course}
                </h4>
                <h5 className="vertical-timeline-element-subtitle">
                    {`${entry.institution} - ${entry.location}`}
                </h5>
                <p>{entry.description}</p>
                <p>{entry.technologies}</p>
            </VerticalTimelineElement>
        );
    }

    const contentStyle =
        entry.endDate === ""
            ? {
                  background: "rgb(33, 150, 243)",
                  color: "#fff",
              }
            : {};

    const contentArrowStyle =
        entry.endDate === ""
            ? {
                  borderRight: "7px solid  rgb(33, 150, 243)",
              }
            : {};

    const iconStyle =
        entry.endDate === ""
            ? {
                  background: "rgb(84, 196, 141)",
                  color: "#fff",
                  transform: "rotate(15deg)",
              }
            : {
                  background: "rgb(83, 209, 214)",
                  color: "#fff",
                  transform: "rotate(-15deg)",
              };

    const Responsibilities = (props: any) => {
        return (
            <div>
                {entry.responsibilities.map((responsibility: any) => {
                    return (
                        <div key={uuidv4()}>
                            <p>
                                {responsibility.description
                                    .split("\n")
                                    .map((d: string) => (
                                        <p key={uuidv4()}>{d}</p>
                                    ))}
                            </p>
                            <p>{responsibility.technologies}</p>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={contentStyle}
            contentArrowStyle={contentArrowStyle}
            date={dateString}
            iconStyle={iconStyle}
            icon={<WorkRoundedIcon />}
        >
            <h4 className="vertical-timeline-element-title">
                {entry.position}
            </h4>
            <h5 className="vertical-timeline-element-subtitle">
                {entry.company}
            </h5>
            <h5 className="vertical-timeline-element-subtitle">
                {entry.location}
            </h5>
            <Responsibilities />
        </VerticalTimelineElement>
    );
};

function Timeline() {
    return (
        <VerticalTimeline>
            {timeline
                .sort(
                    (a: any, b: any) =>
                        new Date(b.startDate).valueOf() -
                        new Date(a.startDate).valueOf()
                )
                .map((item: any) => (
                    <TimeLineItem key={uuidv4()} entry={item} />
                ))}

            <VerticalTimelineElement
                iconStyle={{ background: "rgb(255, 209, 53)", color: "#fff" }}
                icon={<BeachAccessRoundedIcon />}
            />
        </VerticalTimeline>
    );
}

export default Timeline;
