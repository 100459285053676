import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import me from "./assets/images/thatsMe.png";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 150,
        height: 150,
        minWidth: 150,
        minHeight: 150,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "50%",
    },
    thatsMe: {
        borderRadius: "50%",
        width: 140,
        height: 140,
        minWidth: 140,
        minHeight: 140,
    },
}));

function Avatar() {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <img className={classes.thatsMe} src={me} alt="Allan Trabuco" />
        </Paper>
    );
}

export default Avatar;
